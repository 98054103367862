<template>
  <section>
    <div>
      <h1>Error 404</h1>
      <p>Requested page doesn't exist.</p>
      <router-link to="/">Home</router-link>
    </div>
  </section>
</template>

<style scoped>
section {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
}

div {
  text-align: center;
}

h1 {
  font-size: 80px;
}

p {
  margin-bottom: 0.75em;
}

a {
  background-color: rgb(var(--v-theme-primary));
  color: rgb(var(--v-theme-on-primary));
  text-decoration: none;
  padding: 0.25em 0.5em;
  border-radius: 4px;
}
</style>
