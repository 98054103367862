<script setup>
import { inject, ref } from 'vue';
import { useRouter } from 'vue-router';
import { getGuardsToken } from '@/services/api/guards.js';
import { storeGuardsCredentials } from '@/services/guards.js';

const form = ref(null);
const username = ref('');
const password = ref('');

const rules = {
  username: [
    (value) => {
      if (value?.length > 3) return true;
      return 'Username must be at least 3 characters long!';
    },
  ],
  password: [
    (value) => {
      if (value?.length > 6) return true;
      return 'Password must be at least 6 characters long!';
    },
  ],
};

const { updateNotificationOptions } = inject('notificationOptions');

const router = useRouter();
const setTenantData = inject('setTenantData');
const startTokenRefresh = inject('refresh-token');
const login = async () => {
  const { valid } = await form.value.validate();
  if (!valid) return;

  try {
    const { data } = await getGuardsToken(username.value, password.value);
    updateNotificationOptions({
      visible: true,
      type: 'success',
      text: `You're successfully logged in. Please operate carefully.`,
    });

    storeGuardsCredentials(data);
    startTokenRefresh(data.expires_in);

    await setTenantData();

    router.push('/');
  } catch (e) {
    console.log(e);

    updateNotificationOptions({
      visible: true,
      type: 'error',
      text: 'Invalid credentials, please validate your inputs.',
    });
  }
};
</script>

<template>
  <div class="login__wrapper">
    <div class="login">
      <div class="login__logo">
        <img
          src="https://svncms-cdn.s3.eu-central-1.amazonaws.com/assets/cms/production/expous/expous_logo-images/logo-load.webp" />
      </div>
      <div class="login__form">
        <div class="form__header">
          <h1>Welcome back</h1>
          <p>Please enter your valid access information.</p>
        </div>

        <v-form
          ref="form"
          class="form__wrapper">
          <v-text-field
            v-model="username"
            hint="Enter your username to access this website"
            label="Username"
            type="text"
            :rules="rules.username"
            required />

          <v-text-field
            v-model="password"
            hint="Enter your password to access this website"
            label="Password"
            type="password"
            :rules="rules.password"
            required />

          <v-btn
            ripple
            color="primary"
            class="login__sign-in"
            append-icon="mdi-login"
            @click="login">
            Sign in
          </v-btn>
        </v-form>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.login {
  width: 70vw;
  height: 65vh;
  box-shadow: 0 20px 40px 0 rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  display: flex;

  &__wrapper {
    height: 100vh;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
  }

  &__logo {
    min-width: 40%;
    width: 40%;
    height: 100%;
    background: rgba(var(--v-theme-primary), 0.98);
    display: flex;
    justify-content: center;
    align-items: center;

    img:hover {
      transform: scale(1.1);
      transition: all 0.2s ease-in-out;
    }
  }

  &__form {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0 5rem;
    justify-content: center;
    position: relative;
    gap: 1rem;

    .form__header {
    }

    .form__wrapper {
      display: flex;
      flex-direction: column;
      gap: 3rem;
    }

    .v-input {
      max-height: 40px !important;
    }
  }

  &__sign-in {
    align-self: flex-end;
    width: 130px;
  }
}

@media (max-width: 1100px) {
  .login__logo {
    display: none;
  }
}
</style>
