import http from '../http';
import { errorPayload, successPayload } from '@/services/formatter';

async function getSupplementalNameTypes() {
  try {
    const response = await http({
      url: `/enabled-supplemental-name-types`,
      method: 'get',
    });

    return successPayload(response);
  } catch (error) {
    return errorPayload(error);
  }
}

export { getSupplementalNameTypes };
