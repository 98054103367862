/**
 * plugins/vuetify.js
 *
 * Framework documentation: https://vuetifyjs.com`
 */

// Styles
import '@mdi/font/css/materialdesignicons.css';
import 'vuetify/styles';
import '@/assets/scss/main.scss';

// Composables
import { createVuetify } from 'vuetify';

const appTheme = {
  colors: {
    brand: '#155ABB',
    primary: '#009AFF',
    secondary: '#2F3742',
    light: '#ECEFF1',
    info: '#2196F3',
    'status-open': '#DCAB00',
    reverse: '#009AFF',
  },
};

// https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
export default createVuetify({
  theme: {
    themes: {
      light: appTheme,
    },
  },
});
