import { createApp } from 'vue';
import App from './App.vue';

BigInt.prototype.toJSON = function () {
  return this.toString();
};

import { registerPlugins } from '@/plugins';

import './assets/main.css';

const app = createApp(App);

// Plugins
registerPlugins(app);

app.mount('#app');
