import { setCookie, getCookie, eraseCookie } from '@/utils/cookie.js';

export function storeGuardsCredentials(data) {
  setCookie('access-token', data.access_token, data.expires_in);
  setCookie('refresh-token', data.refresh_token, data.refresh_expires_in);
}

export function getGuardsCredentials() {
  const accessToken = getCookie('access-token') ?? '';
  const refreshToken = getCookie('refresh-token') ?? '';

  if (!accessToken || !refreshToken) return null;
  return {
    accessToken,
    refreshToken,
  };
}

export function removeCredentials() {
  eraseCookie('access-token');
  eraseCookie('refresh-token');
}
