import http from '../http';
import { errorPayload, successPayload } from '@/services/formatter';

async function getEnabledLanguages() {
  try {
    const response = await http({
      url: `/enabled-languages`,
      method: 'get',
    });

    return successPayload(response);
  } catch (error) {
    return errorPayload(error);
  }
}

async function getTenantDefaultLanguage() {
  try {
    const response = await http({
      url: `/enabled-languages/default`,
      method: 'get',
    });

    return successPayload(response);
  } catch (error) {
    return errorPayload(error);
  }
}

export { getEnabledLanguages, getTenantDefaultLanguage };
