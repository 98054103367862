import { createRouter, createWebHistory } from 'vue-router';
import Error from '@/views/Error.vue';
import Login from '@/views/Login.vue';
import { getGuardsCredentials } from '@/services/guards.js';

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/login',
      name: 'login',
      component: Login,
    },
    {
      path: '/settings',
      name: 'settings-view',
      component: () => import('@/views/SettingsView.vue'),
      redirect: '/settings/metadata',
      children: [
        {
          path: 'metadata',
          component: () =>
            import('@/components/settings/metadata/MetadataTab.vue'),
          redirect: '/settings/metadata/sports',
          children: [
            {
              path: 'sports/:id?',
              component: () =>
                import(
                  '@/components/settings/metadata/sports/SportsEntity.vue'
                ),
            },
            {
              path: 'categories/:id?',
              component: () =>
                import(
                  '@/components/settings/metadata/categories/CategoriesEntity.vue'
                ),
            },
            {
              path: 'tournaments/:id?',
              component: () =>
                import(
                  '@/components/settings/metadata/tournaments/TournamentsEntity.vue'
                ),
            },
            {
              path: 'tournament-rounds/:id?',
              component: () =>
                import(
                  '@/components/settings/metadata/tournament-rounds/TournamentRounds.vue'
                ),
            },
            {
              path: 'markets/:id?',
              component: () =>
                import(
                  '@/components/settings/metadata/markets/MarketsEntity.vue'
                ),
            },
            {
              path: 'market-groups/:id?',
              component: () =>
                import(
                  '@/components/settings/metadata/market-groups/MarketGroupsEntity.vue'
                ),
            },
            {
              path: 'outcomes/:id?',
              component: () =>
                import(
                  '@/components/settings/metadata/outcomes/OutcomesEntity.vue'
                ),
            },
            {
              path: 'teams/:id?',
              component: () =>
                import('@/components/settings/metadata/teams/TeamsEntity.vue'),
            },
            {
              path: 'players/:id?',
              component: () =>
                import(
                  '@/components/settings/metadata/players/PlayersEntity.vue'
                ),
            },
          ],
        },
        {
          path: 'offer-template/:id?',
          component: () =>
            import('@/components/settings/offer-template/OfferTemplate.vue'),
        },
        {
          path: 'results/:id?',
          component: () =>
            import('@/components/settings/results/ResultsEntity.vue'),
        },
        {
          path: 'promoted-offer',
          component: () =>
            import('@/components/settings/promoted-offer/PromotedOffer.vue'),
          redirect: '/settings/promoted-offer/offer',
          children: [
            {
              path: 'offer/:id?',
              component: () =>
                import(
                  '@/components/settings/promoted-offer/offer/OfferTab.vue'
                ),
            },
            {
              path: 'catalogue/:id?',
              component: () =>
                import(
                  '@/components/settings/promoted-offer/catalogue/CatalogueTab.vue'
                ),
            },
          ],
        },
        {
          path: 'limits/',
          component: () => import('@/components/settings/limits/LimitsTab.vue'),
          redirect: '/settings/limits/global',
          children: [
            {
              path: 'global/:id?',
              component: () =>
                import('@/components/settings/limits/global/GlobalEntity.vue'),
            },
          ],
        },
      ],
    },
    {
      path: '/operations',
      name: 'operations-view',
      component: () => import('@/views/OperationsView.vue'),
      redirect: '/operations/events',
      alias: '/',
      children: [
        {
          path: 'events',
          component: () =>
            import('@/components/operations/events/EventsTab.vue'),
          redirect: '/operations/events/all',
          children: [
            {
              path: 'all/:id?',
              component: () =>
                import('@/components/operations/events/all/AllEvents.vue'),
            },
            {
              path: 'prematch/:id?',
              component: () =>
                import(
                  '@/components/operations/events/prematch/PrematchEvents.vue'
                ),
            },
            {
              path: 'live/:id?',
              component: () =>
                import('@/components/operations/events/live/LiveEvents.vue'),
            },
          ],
        },
        {
          path: 'bets/:id?',
          component: () => import('@/components/operations/bets/BetList.vue'),
        },
        {
          path: 'offer-template-events',
          component: () =>
            import(
              '@/components/operations/offer-template-events/OfferTemplateEvents.vue'
            ),
          redirect: '/operations/offer-template-events/all',
          children: [
            {
              path: 'all/:id?',
              component: () =>
                import(
                  '@/components/operations/offer-template-events/all/AllEvents.vue'
                ),
            },
            {
              path: 'prematch/:id?',
              component: () =>
                import(
                  '@/components/operations/offer-template-events/prematch/PrematchEvents.vue'
                ),
            },
            {
              path: 'live/:id?',
              component: () =>
                import(
                  '@/components/operations/offer-template-events/live/LiveEvents.vue'
                ),
            },
          ],
        },
        {
          path: 'event-results/:id?',
          component: () =>
            import('@/components/operations/event-results/EventResults.vue'),
        },
      ],
    },
    {
      path: '/:pathMatch(.*)*',
      component: Error,
    },
  ],
});

router.beforeEach(async (to, from, next) => {
  const credentials = getGuardsCredentials();
  if (to.name !== 'login' && !credentials) next({ path: '/login' });
  else if (to.name === 'login' && credentials) next({ path: '/' });
  else next();
});

export default router;
