import http from '../http';
import routes from '../env-routes';

async function getTenantData() {
  const tenant = localStorage.getItem('tenantUuid');

  try {
    const response = await http({
      method: 'GET',
      url: `${routes.tenant}/api/v1/tenant/${tenant}`,
    });

    return response.data;
  } catch (error) {
    return error;
  }
}

async function getUserTenants() {
  try {
    const response = await http({
      method: 'GET',
      url: `${routes.aioCore}/user-extents`,
    });

    return response.data;
  } catch (error) {
    return error;
  }
}

export { getTenantData, getUserTenants };
