import routes from './env-routes';

const formatArrayQuery = (queryKey, queryValue) => {
  const valueIsArray = Array.isArray(queryValue);
  const keyIsString =
    typeof queryKey === 'string' || queryKey instanceof String;

  if (!valueIsArray || !keyIsString) return '';

  return queryValue?.map((value) => `${queryKey}=${value}`).join('&');
};

const formatArrayQueries = (params) => {
  const queryParams = Object.keys(params);

  if (!queryParams.length) return '';

  const queryString = queryParams.reduce((query, key) => {
    const queryValue = params[key];

    // language is always sent through base URL so we can prevent language from being sent as a query parameter here globally
    if (key === 'language') return query;

    if (Array.isArray(queryValue) && queryValue.length) {
      const formattedQuery = formatArrayQuery(key, queryValue);
      query.push(formattedQuery);
      return query;
    }

    query.push(`${key}=${queryValue}`);
    return query;
  }, []);

  return queryString.join('&') || '';
};

const successPayload = (response) => ({
  data: response?.data?.data || response?.data || [],
  status: response?.status || 0,
  paginationInfo:
    response?.data?.paginationInfo || response?.data?.pagination || {},
  success: true,
});

const errorPayload = (error) => ({
  data:
    error?.response?.data?.error ||
    error?.text ||
    JSON.parse(error?.response?.data) ||
    error?.message ||
    {},
  status: error?.response?.data?.status || error?.response?.status || 0,
  error: true,
});

const coreURLConstruct = (params = {}) => {
  const tenant = localStorage.getItem('tenantUuid');
  const instance = localStorage.getItem('instance');
  const languageParam = params.language ?? localStorage.getItem('language');

  return `${routes.aioCore}/tenants/${tenant}/instances/${instance}/languages/${languageParam}`;
};

const betslipURLConstruct = () => {
  const tenant = localStorage.getItem('tenantUuid');

  return `${routes.betslip}/api/v1/tenants/${tenant}`;
};

const betsURLConstruct = () => {
  const tenant = localStorage.getItem('tenantUuid');
  const languageParam = localStorage.getItem('language');

  return `${routes.bets}/tenants/${tenant}/languages/${languageParam}`;
};

const betsBaseURLConstruct = (baseUrl) => routes.bets;

const mapLangugageCodes = (languageList) => {
  const languageCodes = {};

  for (const language of languageList) {
    languageCodes[language.id] = language.code;
  }

  return languageCodes;
};

export {
  coreURLConstruct,
  betsURLConstruct,
  betslipURLConstruct,
  betsBaseURLConstruct,
  formatArrayQuery,
  formatArrayQueries,
  errorPayload,
  successPayload,
  mapLangugageCodes,
};
