import http from '../http';
import { errorPayload, successPayload } from '@/services/formatter';

async function getActiveFeedTypes() {
  try {
    const response = await http({
      url: `/feeds/types/tenant/active`,
      method: 'get',
    });

    return successPayload(response);
  } catch (error) {
    return errorPayload(error);
  }
}

async function getActiveFeeds() {
  try {
    const response = await http({
      url: `/feeds/tenant/active`,
      method: 'get',
    });

    return successPayload(response);
  } catch (error) {
    return errorPayload(error);
  }
}

export { getActiveFeeds, getActiveFeedTypes };
