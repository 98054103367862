import axios from 'axios';
import routes from '../env-routes.js';

export function getGuardsToken(username = '', password = '') {
  return axios({
    method: 'POST',
    url: `${routes.guards}/api/token`,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    params: {
      username,
      password,
    },
    data: {},
  });
}

export function getRefreshToken(token) {
  return axios({
    method: 'POST',
    url: `${routes.guards}/api/token/refresh`,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    params: {
      refresh_token: token,
    },
    data: {},
  });
}
