<template>
  <v-app>
    <RouterView />
    <div class="alert-container">
      <v-alert
        v-model="notificationOptions.visible"
        class="included"
        closable
        :text="notificationOptions.text"
        :type="notificationOptions.type"
        @click:close.stop
        @click="notificationOptions.action" />
    </div>
  </v-app>
</template>

<script setup>
import {
  onMounted,
  provide,
  readonly,
  reactive,
  ref,
  onBeforeMount,
} from 'vue';
import { RouterView } from 'vue-router';
import {
  tenantDataKey,
  translationsKey,
  languageCodesKey,
  tenantsListKey,
} from './constants';
import { getActiveFeeds, getActiveFeedTypes } from './services/api/feeds';
import {
  getTenantDefaultLanguage,
  getEnabledLanguages,
} from './services/api/languages';
import { getSupplementalNameTypes } from './services/api/supplementalNameTypes';
import { getTranslations } from './services/translations';
import { mapLangugageCodes } from '@/services/formatter';
import { getTenantData, getUserTenants } from '@/services/api/tenants';
import { getRefreshToken } from '@/services/api/guards.js';
import {
  getGuardsCredentials,
  storeGuardsCredentials,
} from '@/services/guards.js';
import { getCookie } from '@/utils/cookie.js';

const translations = ref({});
const languageCodes = ref({});

const tenantsList = ref([]);

const urlParams = new URLSearchParams(window.location.search);
const qpTenantUuid = urlParams.get('tenantUuid');

if (qpTenantUuid) localStorage.setItem('tenantUuid', qpTenantUuid);

const tenantUuid = qpTenantUuid || localStorage.getItem('tenantUuid');

const tenantData = reactive({
  enabledLanguages: [],
  defaultLanguage: {},
  activeFeedTypes: [],
  activeFeeds: [],
  supplementalNameTypes: [],
  supportedCurrencies: [],
});

provide(tenantDataKey, readonly(tenantData));
provide(translationsKey, readonly(translations));
provide(languageCodesKey, readonly(languageCodes));
provide(tenantsListKey, tenantsList);

const notificationOptions = ref({
  type: 'error',
  text: '',
  visible: false,
  icon: '',
  timeout: 5000,
  action: null,
});

let notificationTimeout = null;

function updateNotificationOptions(data) {
  notificationOptions.value = {
    ...notificationOptions.value,
    ...data,
  };

  notificationTimeout = setTimeout(() => {
    notificationOptions.value.visible = false;

    clearTimeout(notificationTimeout);
    notificationTimeout = null;
  }, notificationOptions.value.timeout);
}

provide('notificationOptions', {
  notificationOptions,
  updateNotificationOptions,
});

async function refreshToken() {
  const token = getCookie('refresh-token');
  if (!token) return;

  try {
    const response = await getRefreshToken(token);
    storeGuardsCredentials(response.data);
    return response.data.expires_in;
  } catch (e) {
    console.log(e);
    return null;
  }
}

let tokenRefreshTimeout = null;
function startTokenRefresh(refresh) {
  clearTimeout(tokenRefreshTimeout);

  tokenRefreshTimeout = setTimeout(async () => {
    clearTimeout(tokenRefreshTimeout);
    const expiresIn = await refreshToken();
    if (expiresIn) startTokenRefresh(expiresIn);
  }, refresh * 1000);
}
provide('refresh-token', startTokenRefresh);

async function fetchTenants() {
  // Prevent fetching tenants on Login page
  if (!getCookie('access-token')) return false;
  try {
    const tenants = await getUserTenants();
    tenantsList.value = tenants.data;
    return tenants.data;
  } catch (e) {
    return e;
  }
}

async function setTenantData() {
  const lsTenantUuid = localStorage.getItem('tenantUuid');
  if (!tenantsList.value?.length) {
    await fetchTenants();
    const firstAvailableTenant = tenantsList.value[0]?.uuid;

    if (!lsTenantUuid) {
      localStorage.setItem('tenantUuid', firstAvailableTenant);
    } else {
      const foundTenantInList = tenantsList.value?.find(
        (tenant) => tenant.uuid === lsTenantUuid,
      );
      if (!foundTenantInList) {
        localStorage.setItem('tenantUuid', firstAvailableTenant);
      }
    }
  }

  Promise.all([
    getEnabledLanguages(),
    getTenantDefaultLanguage(),
    getSupplementalNameTypes(),
    getActiveFeedTypes(),
    getActiveFeeds(),
    getTranslations({ language: 'en' }),
    getTenantData(),
  ])
    .then((res) => {
      tenantData.enabledLanguages = !res[0].error ? res[0].data : [];
      tenantData.defaultLanguage = !res[1].error ? res[1].data : {};
      tenantData.supplementalNameTypes = !res[2].error ? res[2].data : [];
      tenantData.activeFeedTypes = !res[3].error ? res[3].data : [];
      tenantData.activeFeeds = !res[4].error ? res[4].data : [];
      translations.value = !res[5].error ? res[5].data : [];
      tenantData.supportedCurrencies = res[6].supportedCurrencies ?? [];

      if (tenantData.enabledLanguages.length) {
        languageCodes.value = mapLangugageCodes(tenantData.enabledLanguages);
      }
      return true;
    })
    .catch((error) => {
      console.error(error);
      updateNotificationOptions({
        visible: true,
        type: 'error',
        text: 'Something went wrong with fetching data. Please try again later.',
      });
      return error;
    });
}

function resetTenantData() {
  tenantData.value = {
    enabledLanguages: [],
    defaultLanguage: {},
    activeFeedTypes: [],
    activeFeeds: [],
    supplementalNameTypes: [],
    supportedCurrencies: [],
  };

  tenantsList.value = [];
}

provide('setTenantData', setTenantData);
provide('resetTenantData', resetTenantData);

onBeforeMount(async () => {
  const expiresIn = await refreshToken();
  if (expiresIn) startTokenRefresh(expiresIn);
});

onMounted(async () => {
  localStorage.setItem('instance', '0');
  localStorage.setItem('language', 'en');

  const credentials = getGuardsCredentials();
  if (!credentials) return;

  setTenantData();
});
</script>

<style lang="scss">
// Remove this once filter component is created
.filters {
  gap: 2rem;

  & > div:first-child {
    flex-wrap: wrap;
    gap: 0.25rem;
  }

  & > div:last-child {
    align-self: flex-end;
  }

  &__status {
    .v-field__input {
      min-width: 80px;
    }
  }

  &__live-status,
  &__language {
    .v-field__input {
      min-width: 110px;
    }
  }

  &__prematch-status,
  &__games {
    .v-field__input {
      min-width: 160px;
    }
  }

  &__feeds {
    .v-field__input {
      min-width: 170px;
    }
  }
}

.v-menu {
  & > .v-overlay__content {
    & > .v-list {
      overflow-x: overlay !important;

      &::-webkit-scrollbar {
        width: 6px;
        background: transparent;
        border-radius: 0.25rem;
        opacity: 0;
        transition: all 0.3s ease;
      }

      &::-webkit-scrollbar-thumb {
        background: rgba(#ccc, 0.3);
        border-radius: 0.25rem;
        transition: all 1.3s ease;
      }

      &:hover::-webkit-scrollbar-thumb {
        background: rgba(#aaa, 0.5);
      }
    }
  }
}

.alert-container {
  position: fixed;
  z-index: 2450;
  bottom: 60px;
  left: 50%;
  transform: translateX(-50%);
  min-width: 30vw;
}
</style>
