export function setCookie(name, value, seconds) {
  const date = new Date();
  date.setTime(date.getTime() + seconds * 1000);

  const expires = `expires=${date.toUTCString()}`;
  document.cookie = `${name}=${value};${expires};path=/`;
  document.cookie = `${name}-expiration=${expires};${expires};path=/`;
}

export function getCookie(name) {
  const nameEQ = `${name}=`;
  const cookie = document.cookie.split('; ')?.find((c) => c.startsWith(nameEQ));
  return cookie ? cookie.substring(nameEQ.length) : null;
}

export function eraseCookie(name) {
  document.cookie = `${name}=; Path=/; Max-Age=0;`;
}
