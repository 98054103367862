import axios from 'axios';
import routes from './env-routes';

const http = axios.create({
  baseURL: routes.tc,
  timeout: 10000,
});

const ADMIN_APP_ID = 'a1da95f58ad6968da18bebbb66a73129';

const getUrl = (language, companyUuid) => {
  const projectsLocale = `/translations/${ADMIN_APP_ID}/locales/${language}`;
  const tenantLocale = companyUuid
    ? `${projectsLocale}/tenants/${companyUuid}`
    : projectsLocale;

  return `${tenantLocale}?includeLocaleFallback=true`;
};

const getTranslations = ({ language, companyUuid }) => {
  const url = getUrl(language, companyUuid);

  return http
    .get(url)
    .then((response) => response.data)
    .catch((err) => {
      console.warn('Error getting translations: ', err.toJSON());
      return err.toJSON();
    });
};

export { getTranslations };
