/* LOCAL STORAGE KEYS */

export const LS_OPERATIONS_ALL_EVENTS_TOURNAMENTS_FILTERS_KEY =
  '/operations/all-events-tournaments/filters';

export const LS_OPERATIONS_PREMATCH_EVENTS_TOURNAMENTS_FILTERS_KEY =
  '/operations/prematch-events-tournaments/filters';

export const LS_OPERATIONS_LIVE_EVENTS_TOURNAMENTS_FILTERS_KEY =
  '/operations/live-events-tournaments/filters';

export const LS_OPERATIONS_ALL_OFFER_TEMPLATE_EVENTS_TOURNAMENTS_FILTERS_KEY =
  '/operations/all-offer-template-events-tournaments/filters';

export const LS_OPERATIONS_PREMATCH_OFFER_TEMPLATE_EVENTS_TOURNAMENTS_FILTERS_KEY =
  '/operations/prematch-offer-template-events-tournaments/filters';

export const LS_OPERATIONS_LIVE_OFFER_TEMPLATE_EVENTS_TOURNAMENTS_FILTERS_KEY =
  '/operations/live-offer-template-events-tournaments/filters';

export const LS_SETTINGS_SPORTS_FILTERS_KEY = '/settings/sports/filters';
export const LS_SETTINGS_OFFER_TEMPLATE_FILTERS_KEY =
  '/settings/offer-template/filters';
export const LS_SETTINGS_CATEGORIES_FILTERS_KEY =
  '/settings/categories/filters';
export const LS_SETTINGS_MARKETS_FILTERS_KEY = '/settings/markets/filters';
export const LS_SETTINGS_TOURNAMENTS_FILTERS_KEY =
  '/settings/tournaments/filters';
export const LS_SETTINGS_TOURNAMENT_ROUNDS_FILTERS_KEY =
  '/settings/tournament-rounds/filters';
export const LS_SETTINGS_OUTCOMES_FILTERS_KEY = '/settings/outcomes/filters';
export const LS_SETTINGS_TEAMS_FILTERS_KEY = '/settings/teams/filters';
export const LS_SETTINGS_PLAYERS_FILTERS_KEY = '/settings/players/filters';
export const LS_SETTINGS_MARKET_GROUPS_FILTERS_KEY =
  '/settings/market-groups/filters';

export const LS_SETTINGS_RESULTS_FILTERS_KEY = '/settings/results/filters';

export const LS_SETTINGS_BETS_FILTERS_KEY = '/operations/bets/filters';
export const LS_SETTINGS_BET_SELECTIONS_FILTERS_KEY =
  '/operations/bets/selections';

export const LS_SETTINGS_EVENT_RESULTS_FILTERS_KEY =
  '/operations/event-results/filters';

export const LS_SETTINGS_PROMOTED_OFFER_FILTERS_KEY =
  '/settings/promoted-offer/filters';

export const LS_SETTINGS_PROMOTED_CATALOGUE_FILTERS_KEY =
  '/settings/promoted-catalogue/filters';

/* INJECTION KEYS */

export const tenantDataKey = Symbol('tenantData');
export const translationsKey = Symbol('translations');
export const languageCodesKey = Symbol('languageCodes');
export const tenantsListKey = Symbol('tenantsList');

/* APP DEFAULT VALUES */
export const DEFAULT_EVENTS_DATE_RANGE = 7;
export const DEFAULT_RESULTS_DATE_RANGE = 0;
